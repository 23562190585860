// import Sticky from "sticky-js";

import StickyEvents from 'sticky-events';

export class Menu {

    constructor() {
        this.breakpoint = 1180;
        this.sticky = true;
        this.stickyBreakpoint = 1180;

        this.selector = {
            desk: {
                container: '#panel-outer',
                sticky: '.catalog-menu__container',
                menu: {
                    outer: '.catalog-menu',
                    root:  '.catalog-menu__root'
                }
            },
            mobile: {

            }
        };

        // DESKTOP
        this.$desk = {};
        this.$desk.container = $(this.selector.desk.container);

        this.$desk.menu = {};
        this.$desk.menu.outer = this.$desk.container.find(this.selector.desk.menu.outer);
        this.$desk.menu.root = this.$desk.container.find(this.selector.desk.menu.root);

        this.catalogMenuPrevent = false;
        this.catalogMenuTimeout = 0;
        //
        // this.stickyEvents = new StickyEvents({
        //     // container: this.$desk.container[0],
        //     // container: document.querySelector('#menu'),
        //     // enabled: false,
        //     stickySelector: this.selector.desk.sticky
        // });

        // MOBILE
        this.mobile = {};


        // INIT
        this.bind();
        this.resize();
    }

    resize() {
        this.windowWidth = $(window).width();
        // this.windowWidth = $(document).width();

        // console.log(this.windowWidth);

        if (this.windowWidth > this.breakpoint) {
            this.init();
            this.initSticky();
        } else {
            this.initMobile();
        }

        // console.log(this.windowWidth);
    }

    bind() {

        const plugin = this;

        window.onresize = () => {
            plugin.resize();
        };

        $(plugin.selector.desk.menu.root + '>.collapse').on('show.bs.collapse', function (e) {
            plugin.$desk.menu.root.removeClass('active');
            $(e.target).closest(plugin.selector.desk.menu.root).addClass('active');
        });
    }

    unbind(type) {

        switch (type) {
            case 'desc':
                // this.$el.desc.unbind();
                break;

            case 'mobile':
                break;
        }
    }

    init() {

        const plugin = this;

        this.$desk.menu.root.on('mouseleave', function (e) {
            clearTimeout(plugin.catalogMenuTimeout);
        });

        this.$desk.menu.root.on('mouseenter', '.catalog-menu__toggle', function (e) {

            if (plugin.catalogMenuPrevent) {
                return;
            }

            const $collapse = $(this).parent().next('.collapse:not(.show)');

            plugin.catalogMenuTimeout = setTimeout(function(){
                plugin.catalogMenuPrevent = true;
                $collapse.collapse('show');

                setTimeout(function(){
                    plugin.catalogMenuPrevent = false;
                }, 250)

            }, 150);
        });

    }

    initMobile() {

        if (this.windowWidth >= 1200 ) {
            return;
        }

        if (this.mobile.menu && this.mobile.$mobileMenu) {

            this.mobile.$mobileMenu.html(this.mobile.menu);

            return;
        }

        this.mobile.$catalogMenu = $('.catalog-menu__container');
        this.mobile.$defaultMenu = $('.header-menu');
        this.mobile.$mobileMenu = $('#dropdownMobileMenu + .dropdown-menu');

        this.mobile.menu = this.mobile.$catalogMenu.html();

        this.mobile.menu += this.mobile.$defaultMenu.html();

        // this.mobile.$catalogMenu.html('');
        // this.mobile.$defaultMenu.html('');


        this.mobile.$mobileMenu.html(this.mobile.menu);



        $(document).on('click', '#dropdownMobileMenu + .dropdown-menu', function (e) {
            e.stopPropagation();
        });
    }

    // STICKY
    initSticky() {

        // console.log(this.sticky);
        // console.log(this.windowWidth);
        // console.log(this.stickyBreakpoint);

        if (!this.sticky || this.windowWidth < this.stickyBreakpoint) {
            return;
        }

        const plugin = this;

        plugin.stickyEvents = new StickyEvents({
            // container: this.$desk.container[0],
            // container: document.querySelector('#menu'),
            // enabled: false,
            stickySelector: plugin.selector.desk.sticky
        });

        plugin.stickyEvents.enableEvents();

        const { stickyElements } = plugin.stickyEvents;

        plugin.stickyInitiated = false;
        // const offsetInit = this._getCoords(plugin.$desk.container[0]);

        const windowTopOffset = $(window).scrollTop();

        const stickyOffset = [];

        // console.log(stickyElements);

        // plugin.activeMenu = false;
        // plugin.activeMenu = this.$desk.menu.outer.find(plugin.selector.desk.menu.root + '.active>.collapse.show');

        stickyElements.forEach(sticky => {

            let stickyInitiated = false;
            // let coords = plugin._getCoords(sticky);

            // console.log(coords);
            // console.log($(sticky));



            sticky.addEventListener(StickyEvents.CHANGE, (event) => {
                // console.log(event);
                sticky.classList.toggle('state--stuck', event.detail.isSticky);
            });

            sticky.addEventListener(StickyEvents.STUCK, (event) => {

                // sticky.classList.toggle('state--stuck', event.detail.isSticky);

                // if (!plugin.stickyInitiated) {
                //     plugin.stickyInitiated = true;
                //     return;
                // }

                // plugin.activeMenu = this.$desk.menu.outer.find(plugin.selector.desk.menu.root + '.active>.collapse.show');

                // if (plugin.activeMenu && plugin.activeMenu.length) {
                //     plugin.activeMenu.collapse('hide');
                // }


                // plugin.$desk.menu.root.find('>.collapse.show').collapse('hide');
                // console.log('stuck');
                // console.log(event);

                // sticky.classList.add('sticky--stuck');
            });

            sticky.addEventListener(StickyEvents.UNSTUCK, (event) => {

                // sticky.classList.remove('sticky--stuck');
                // sticky.classList.add('sticky--unstuck');


                // console.log(plugin.activeMenu);

                // if (plugin.activeMenu && plugin.activeMenu.length) {
                //     plugin.activeMenu.collapse('show');
                //
                //     // plugin.activeMenu = false;
                // }

                // console.log(plugin.activeMenu);
            });
        });

        // this.stickyEvents.enableEvents();
    }

    _getCoords(elem) {
        const box = elem.getBoundingClientRect();

        return {
            top: box.top + pageYOffset,
            left: box.left + pageXOffset
        };

    }

}