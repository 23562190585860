export class Cart {

    constructor() {
        this.api = {
            load: 'index.php?route=common/cart/load',
            price_mode: 'index.php?route=common/cart/price_mode',

            load_refine: 'index.php?route=checkout/calculate/refine_options',

            add: 'index.php?route=checkout/cart/add',
            update: 'index.php?route=checkout/cart/edit',
            remove: 'index.php?route=checkout/cart/remove',
        };

        this.selector = {
            widget: '.cart-widget',
            modal: {
                cart: '#modal-cart',
                refine: '#modal-refine-cart',
            },
            content: '#cartContent',
            button: {
                add: '.js-cartAdd',
                remove: '.js-cartRemove',
                change_price_mode: '.js-cartPriceMode',
                refine_show: '.js-showRefine',
                // refine_apply: '.js-cartRefine',
            },
            product: {
                item: '.product-item',
                card: '.product-card',
            },
            cart: {
                item: '.cart-item',
            },
            input: {
                quantity: '.js-productQuantity',
                option: 'input[name^="option["',
                cart: {
                    quantity: '.js-cartQuantity'
                }

            },
            page_id: {
                checkout: 'clever-checkout',
                cart: 'checkout-cart'
            },
            checkout_cart: '#cart-details'
        };

        this.stateClass = {
            widget: {
                loading: 'cart--loading'
            },
            button: {
                in_cart: 'product--in-cart'
            }
        };

        this.default = {
            quantity: {
                min: 1,
                max: 5000,
            },
        };

        this.$modal = $(this.selector.modal.cart);
        this.$progress = this.$modal.find('.progress-bar');
        this.$widget = $(this.selector.widget);
        this.$content = $(this.selector.content);

        this.addTimeout = 0;
        this.removeTimeout = 0;
        this.quantityTimeout = 0;

        this.notify = new Notify();

        this._init();


        this.$modalRefine = {
            length: 0
        };
        this._refineModal();

        this._bindEvents();
    }


    _init() {
        const plugin = this;
    }

    _bindEvents() {
        const plugin = this;

        $(document).on('click', plugin.selector.button.add, function () {
            const product_id = $(this).data('id');

            const $outer = plugin._getProductOuter($(this));

            let quantity = null;
            if ($outer.length) {
                const $inputQuantity = $outer.find(plugin.selector.input.quantity);
                quantity = plugin._getInputQuantity($inputQuantity, quantity);
            }

            let options = plugin._getOptionValues($outer);

            plugin.add(product_id, quantity, options);
        });

        $(document).on('click', plugin.selector.button.remove, function () {

            const $outer = plugin._getProductOuter($(this));

            if ($outer.length) {
                const cart_id = $outer.data('cid');
                const product_id = $outer.data('pid');
                const hasOption = $outer.data('option');
                plugin.remove(cart_id, product_id, $(this));
            }
        });

        $(document).on('change', plugin.selector.input.cart.quantity, function () {

            const $input = $(this);
            const $outer = plugin._getProductOuter($input);

            if ($outer.length) {
                const cart_id = $outer.data('cid');
                const product_id = $outer.data('pid');

                let quantity = plugin._getInputQuantity($input, 0);

                let options = plugin._getOptionValues($outer);

                if (plugin.quantityTimeout !== 0) {
                    clearTimeout(plugin.quantityTimeout);
                }

                plugin.quantityTimeout = setTimeout(function () {

                    plugin.quantityTimeout = 0;

                    let minimum = 1;
                    if ($input.attr('min')) {
                        minimum = parseFloat($input.attr('min'));
                    }

                    let maximum = null;
                    if ($input.attr('max')) {
                        maximum = parseFloat($input.attr('max'));
                    }

                    if (minimum && (!quantity || quantity < minimum)) {
                        quantity = minimum;
                    } else if (maximum && maximum < quantity) {
                        quantity = maximum;
                    }

                    if (cart_id) {
                        plugin.update(cart_id, quantity, options);
                    }

                }, 300);
            }
        });

        $(document).on('click', plugin.selector.button.change_price_mode, function () {

            const mode = $(this).data('mode');

            $.ajax({
                url: plugin.api.price_mode,
                type: 'post',
                data: {
                    price_mode: mode,
                    is_ajax: true,
                },
                dataType: 'json',
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            }).done(function (json) {

                if (json.redirect) {
                    if ($('body').attr('id') === plugin.selector.page_id.cart) {
                        window.location = 'index.php?route=checkout/cart';
                    } else if ($('body').attr('id') !== plugin.selector.page_id.checkout) {
                        plugin.load();

                        plugin.$modal.attr('data-reload-page', true);

                    } else {
                        $(plugin.selector.checkout_cart).trigger('updated');
                    }
                }
            });
        });

        $(document).on('click', plugin.selector.button.refine_show, function () {

            const product_id = $(this).data('id');

            const $outer = plugin._getProductOuter($(this));

            let quantity = null;
            let options = {};

            if ($outer.length) {
                const $inputQuantity = $outer.find(plugin.selector.input.quantity);
                quantity = plugin._getInputQuantity($inputQuantity, quantity);

                options = plugin._getOptionValues($outer);
            }

            plugin.showRefine(product_id, quantity, options);
        });

        plugin.$modal.on('hide.bs.modal', function (e) {
            if (plugin.$modal.data('reload-page')) {
                console.log('reload');

                let url = new URL(window.location.href);
                url.searchParams.delete('price_mode');

                const locationOverwrite = url.toString();

                window.location.replace(locationOverwrite);
            }
        });

        plugin.$modalRefine.on('hide.bs.modal', function (e) {
            plugin.$modalRefine.find('.modal-title').html('');
            plugin.$modalRefine.find('.modal-body').html('');
        });
    }

    // HELPERS
    _getProductOuter($element) {
        let $outer = $element.closest(this.selector.product.item);
        if (!$outer.length) {
            $outer = $element.closest(this.selector.product.card);
        }

        if (!$outer.length) {
            $outer = $element.closest(this.selector.cart.item);
        }

        if ($outer.length) {
            return $outer;
        }

        return {
            length: null,
            find: function () {
                return {
                    length: null
                }
            }
        };
    }

    _getInputQuantity($input, if_null) {
        const plugin = this;

        if (!$input.length) {
            if_null = if_null || 1;
            return if_null;
        }

        const quantity = Number($input.val());
        const min = Number($input.attr('min')) || plugin.default.quantity.min;
        const max = Number($input.attr('max')) || plugin.default.quantity.max;

        let value = quantity;
        if (!plugin._isNumeric(value)) {
            value = min;
        }

        if (value > max) {
            value = max;
        }
        if (value < min) {
            value = min;
        }

        if (quantity !== value) {
            $input.val(value);
        }

        return value;
    }

    _getOptionValues($outer) {
        const plugin = this;
                let options = {};

        $outer.find(plugin.selector.input.option).each(function (i, elem) {
            if ($(elem).attr('type') !== 'radio' || $(elem).is(":checked")) {

                let pid = $(elem).attr('name').replace('option[', '').replace(/]+$/,'');
                options[pid] = $(elem).val();
            }
        });

        return options;
    }

    _isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    _refineModal() {
        const plugin = this;

        if (!plugin.$modalRefine.length) {

            const modalID = plugin.selector.modal.refine.replace('#', '');

            let html = '<div id="' + modalID + '" class="modal" tabindex="-1" role="dialog" aria-labelledby="' + modalID + 'Label" aria-hidden="true">';
            html += '        <div class="modal-dialog" role="document">';
            html += '            <div class="modal-content">';
            html += '                <div class="modal-header">';
            html += '                   <h5 class="modal-title" id="exampleModalLabel">Выберите необходимые опции для заказа</h5>';
            html += '                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
            html += '                </div>';
            html += '                <div class="modal-body px-lg-5">';
            html += '                </div>';
            // html += '                <div class="modal-footer justify-content-center">';
            // html += '                   <button type="button" class="btn btn-primary">Добавить в корзину</button>';
            // html += '                </div>';
            html += '            </div>';
            html += '        </div>';
            html += '    </div>';


            $('body').append(html);

            plugin.$modalRefine = $(plugin.selector.modal.refine);

            // plugin.$modalRefine.modal();
        }
    }


    showRefine(product_id, quantity, options) {
        const plugin = this;

        if (!plugin.$modalRefine.length) {
            return;
        }

        $.ajax({
            url: plugin.api.load_refine,
            type: 'post',
            dataType: 'json',
            data: {
                product_id: product_id,
                quantity: quantity,
                options: options || {}
            },
            beforeSend: function () {
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (json) {

            // console.log(json);

            if (json.html) {
                plugin.$modalRefine.find('.modal-title').html(json.name);
                plugin.$modalRefine.find('.modal-body').html('<div class="product-card" data-id="' + product_id + '">' + json.html + '</div>');

                plugin.$modalRefine.modal('show');
            }
        });

    }

    add(product_id, quantity, options) {

        const plugin = this;

        if (!product_id) {
            return;
        }

        const $button = $(plugin.selector.button.add + '[data-id="' + product_id + '"], ' + plugin.selector.button.refine_show + '[data-id="' + product_id + '"]');

        $.ajax({
            url: plugin.api.add,
            type: 'post',
            data: {
                product_id: product_id,
                quantity: (typeof (quantity) !== 'undefined') ? quantity : 1,
                option : options || {}
            },
            dataType: 'json',
            beforeSend: function () {

                plugin.$widget.addClass(plugin.stateClass.widget.loading);

                if ($button) {
                    $button.addClass('loading').prop('disabled', true);

                    plugin.addTimeout = setTimeout(function () {
                        $button.removeClass('loading').prop('disabled', false);
                    }, 5000);
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {

                $button.removeClass('loading').prop('disabled', false);
                plugin.$widget.removeClass(plugin.stateClass.widget.loading);

                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (json) {

            // console.log(json);

            if (json['redirect']) {

                window.location = json['redirect'];

            } else if (json['success']) {

                $('.modal').modal('hide');

                plugin.load();
                plugin.notify.show(json.success.message, 'success', 3000, json.success.image);
            }

            plugin.$widget.removeClass(plugin.stateClass.widget.loading);

            console.log($button);

            if ($button.length) {

                $button.removeClass('loading').prop('disabled', false).addClass(plugin.stateClass.button.in_cart);

                if (json.success && json.success.hasOwnProperty('button')) {

                    const $buttonText = $button.find('.text');

                    const text = $button.data('text-in-cart') || json.success.button;
                    $buttonText.html(text);
                }
            }
        });
    }

    update(cart_id, quantity) {

        const plugin = this;

        if (!cart_id) {
            return;
        }

        let quantitySet = {};
        quantitySet[cart_id] = (typeof (quantity) !== 'undefined' ? quantity : 1);

        $.ajax({
            url: plugin.api.update,
            type: 'post',
            data: {
                quantity: quantitySet,
                is_ajax: true
            },
            dataType: 'json',
            beforeSend: function () {
                // if (plugin.$progress.length) {
                //
                //     plugin.$progress.closest('.modal-progress').removeClass('invisible');
                //
                //     plugin.$progress.addClass('active');
                //
                //     progressInterval = setInterval(function(){
                //         if (progressStatus < 90) {
                //             progressStatus = progressStatus + 27;
                //             plugin.$progress.css('width', progressStatus + '%').attr('aria-valuenow', progressStatus);
                //         }
                //     }, 100);
                // }
            },
            complete: function () {
                // clearInterval(progressInterval);
                // progressStatus = 0;
                // plugin.$progress.css('width', '99%').attr('aria-valuenow', '99').removeClass('active').removeAttr('aria-valuenow').removeAttr('style');
                // plugin.$progress.closest('.modal-progress').addClass('invisible');
            },
            error: function (xhr, ajaxOptions, thrownError) {
                // clearInterval(progressInterval);
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (json) {

            if (json.redirect) {
                if ($('body').attr('id') === plugin.selector.page_id.cart) {
                    window.location = 'index.php?route=checkout/cart';
                } else if ($('body').attr('id') !== plugin.selector.page_id.checkout) {
                    plugin.load();
                } else {
                    $(plugin.selector.checkout_cart).trigger('updated');
                }
            }
        });

    }

    remove(cart_id, product_id, $button) {

        const plugin = this;

        if (!cart_id) {
            return;
        }

        $.ajax({
            url: plugin.api.remove,
            type: 'post',
            data: 'key=' + cart_id,
            dataType: 'json',
            beforeSend: function () {
                if ($button.length) {
                    $button.addClass('loading').prop('disabled', true);
                    $button.closest(plugin.selector.cart.item).css('opacity', '0.3');
                }
            },
            complete: function () {
                // if ($button.length) {
                //     $button.removeClass('loading').prop('disabled', false);
                // }
            },
            success: function (json) {

                console.log(json);

                if (!json.success) {
                    return;
                }

                if ($('body').attr('id') === plugin.selector.page_id.cart) {
                    window.location = 'index.php?route=checkout/cart';
                } else {

                    if (plugin.removeTimeout !== 0) {
                        clearTimeout(plugin.removeTimeout);
                    }
                    plugin.removeTimeout = setTimeout(function () {
                        plugin.removeTimeout = 0;

                        if ($('body').attr('id') !== plugin.selector.page_id.checkout) {

                            const remove_pid = json.removed_id || 0;

                            plugin.load(remove_pid);
                        } else {
                            $(plugin.selector.checkout_cart).trigger('updated');
                        }

                    }, 50);
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }

    load(remove_pid) {

        const plugin = this;

        $.ajax({
            url: plugin.api.load,
            type: 'get',
            dataType: 'json',
            beforeSend: function () {
                plugin.$widget.removeClass('cart--loaded');
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (cart) {

            // console.log($widget.length || $panel.length);

            if (plugin.$content.length) {
                plugin.$content.html(cart.html);

                if (cart.empty) {
                    // $('.cart-panel').addClass('cart--empty');
                }

                // plugin.$content.removeClass('cart--empty');
            }

            if (plugin.$widget.length) {

                if (cart.data.counter) {
                    plugin.$widget.removeClass('cart--empty').addClass('cart--loaded');
                    plugin.$widget.find('.cart-counter').text(cart.data.counter);

                } else if (cart.empty) {
                    plugin.$widget.addClass('cart--empty');
                    plugin.$widget.find('.cart-counter').text(cart.data.counter);
                }

                plugin.$widget.find('.cart-amount-total').html(cart.data.text_amount);
                plugin.$widget.find('.cart-button__label').html(cart.data.text_items);

                // plugin.$widget.find('.cart-button__label').text(cart.data.widget.label);

            } else if ($('body').attr('id') === plugin.selector.page_id.checkout) {
                //$('#cart-details').html(cart.panel);

                //console.log($('#cart-details'));
            }

            // STORE BUTTONS
            if (remove_pid) {

                if (plugin.$content.length && !cart.data.counter) {
                    plugin.$modal.modal('hide');
                }

                const $button = $(plugin.selector.button.add + '[data-id="' + remove_pid + '"], ' + plugin.selector.button.refine_show + '[data-id="' + remove_pid + '"]');

                const text = $button.data('text-default') || 'В корзину';

                if ($button.length) {
                    $button.removeClass(plugin.stateClass.button.in_cart);

                    const $buttonText = $button.find('.text');
                    if ($buttonText && text) {
                        $buttonText.html(text);
                    }
                }
            }
        });
    }
}

export class Voucher {
    constructor() {
        this.api = {
            // add: 'index.php?route=account/wishlist/add',
            remove: 'index.php?route=checkout/cart/remove',
        };

        this.selector = {
            button: {
                remove: '.js-voucherRemove',
            },
        };
    }

    _init() {

        const plugin = this;

        // $(document).on('click', plugin.selector.button.remove, function () {
        //
        //     const $outer = plugin._getProductOuter($(this));
        //
        //     if ($outer.length) {
        //         const cart_id = $outer.data('cid');
        //         const product_id = $outer.data('pid');
        //         plugin.remove(cart_id, product_id, $(this));
        //     }
        // });
    }
}

export class WishList {
    constructor() {

        this.notify = new Notify();


        this.api = {
            add: 'index.php?route=account/wishlist/add',
            remove: 'index.php?route=account/wishlist/remove',
            toggle: 'index.php?route=account/wishlist/toggle',
        };

        this.selector = {
            button: '.js-wishList',
            total: '.js-wishListTotal',
            item: '.wishlist-item'
        };

        this.stateClass = {
            active: 'active',
        };

        this._init();
    }

    _init() {
        const plugin = this;

        $(document).on('click', plugin.selector.button, function () {

            const product_id = $(this).data('id');

            if (product_id) {
                plugin.toggle(product_id);
            }
        });
    }

    toggle(product_id) {

        const plugin = this;

        $.ajax({
            url: plugin.api.toggle,
            type: 'post',
            data: 'product_id=' + product_id,
            dataType: 'json',

            success: function (json) {

                // console.log(json);

                if (json.success) {
                    const $button = $(plugin.selector.button + '[data-id=' + product_id + ']');

                    const status = Number(json.success.status);

                    if (status === 1) {
                        $button.addClass(plugin.stateClass.active);
                    } else if (status === 0) {
                        $button.removeClass(plugin.stateClass.active);

                        const $outer = $button.closest(plugin.selector.item);
                        if ($outer.length) {
                            $outer.remove();
                        }
                    }

                    if (json.success.message) {
                        let timeout = 3000;
                        if (json.success.is_guest && status === 1) {
                            timeout = 6000;
                        }

                        let color = 'primary';
                        if (status === 2) {
                            color = 'danger';
                        }

                        plugin.notify.show(json.success.message, color, timeout, json.success.image);
                    }

                    if (json.total) {
                        $(plugin.selector.total).html(json.total);
                    }

                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }
}

export class Compare {
    constructor() {

        this.notify = new Notify();

        this.api = {
            add: 'index.php?route=product/compare/add',
            remove: 'index.php?route=product/compare/remove',
            toggle: 'index.php?route=product/compare/toggle',
        };

        this.selector = {
            button: {
                add: '.js-compareAdd',
                remove: '.js-compareRemove',
                toggle: '.js-compare',
            },
            total: '.js-compareTotal',
            item: '.compare-item'
        };

        this.stateClass = {
            active: 'active',
        };

        this._init();
    }

    _init() {

        const plugin = this;

        $(document).on('click', plugin.selector.button.toggle, function () {

            const product_id = $(this).data('id');

            if (product_id) {
                plugin.toggle(product_id);
            }
        });
    }

    toggle(product_id) {

        const plugin = this;

        $.ajax({
            url: plugin.api.toggle,
            type: 'post',
            data: 'product_id=' + product_id,
            dataType: 'json',

            success: function (json) {

                // console.log(json);

                if (json.success) {
                    const $button = $(plugin.selector.button.toggle + '[data-id=' + product_id + ']');

                    const status = Number(json.success.status);

                    if (status === 1) {
                        $button.addClass(plugin.stateClass.active);
                    } else if (status === 0) {
                        $button.removeClass(plugin.stateClass.active);

                        const $outer = $button.closest(plugin.selector.item);
                        if ($outer.length) {
                            $outer.remove();
                        }
                    }

                    if (json.success.message) {
                        let timeout = 3000;
                        let color = 'primary';

                        plugin.notify.show(json.success.message, color, timeout, json.success.image);
                    }

                    if (json.total) {
                        $(plugin.selector.total).html(json.total);
                    }

                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }
}

export class Notify {
    constructor() {

        const $box = $("#notify");

        if (!$box.length) {
            $("body").append('<div id="notify"><div class="notify-outer"></div></div>');
        }

        this.$container = $("#notify .notify-outer");

        this.timeout = 0;
    }

    show(message, type, timeout, image) {

        type = type || "primary";
        timeout = timeout || 5000;

        let html = '<div class="alert type-' + type + ' alert-dismissible show" role="alert"><div class="media">';
        if (image) {
            html += '<img src="' + image + '" class="mr-4" style="max-width: 80px;">';
        }
        html += '<div class="media-body">' + message + '</div>';
        html += '</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>';

        const $newAlert = $(html);

        const $alerts = this.$container.find('.alert');

        if ($alerts.length) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            $alerts.remove();
        }

        if (timeout) {
            this.timeout = setTimeout(function () {
                if ($newAlert.length) {
                    $newAlert.alert('close')
                }
            }, timeout);
        }

        $newAlert.prependTo(this.$container);
    }
}

export class Agree {

    constructor() {

        $(document).delegate('.agree', 'click', function (e) {
            e.preventDefault();

            $('#modal-agree').remove();

            const element = this;


            let title = 'Информация';
            let heading = '';
            let info = '';
            let html = '';

            $.ajax({
                url: $(element).attr('href') + '&agree=true',
                type: 'get',
                dataType: 'html',
                success: function (data) {

                    let result = {};

                    try {
                        result = JSON.parse(data);
                    } catch (e) {
                        result = {
                            info: data
                        };
                    }

                    info = result.info;
                    title = result.title || title;

                    // heading = result.heading || $(element).text().charAt(0).toUpperCase() + $(element).text().slice(1);
                    // heading = '<h5>' + heading + '</h5><hr>';

                    html = '<div id="modal-agree" class="modal fade">';
                    html += '  <div class="modal-dialog modal-lg modal-dialog-scrollable">';
                    html += '    <div class="modal-content">';
                    html += '      <div class="modal-header">';
                    html += '        <h6 class="modal-title">' + title + '</h6>';
                    html += '        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>';
                    html += '      </div>';
                    html += '      <div class="modal-body">' + heading + info + '</div>';
                    html += '    </div>';
                    html += '  </div>';
                    html += '</div>';

                    $('body').append(html);

                    $('#modal-agree').modal('show');
                }
            });
        });

        // tooltips on hover
        $('[data-toggle=\'tooltip\']').tooltip({container: 'body'});

        // Makes tooltips work on ajax generated content
        $(document).ajaxStop(function () {
            $('[data-toggle=\'tooltip\']').tooltip({container: 'body'});
        });

    }

}

export class CookiePolicy {

    constructor() {

    }

    init() {

        $('#button-cookie').on('click', function (e) {
            e.preventDefault();

            const $button = $(this);

            $.ajax({
                url: 'index.php?route=common/cookie/agree',
                dataType: 'json',
                beforeSend: function () {
                    $button.button('loading');
                },
                complete: function () {
                    $button.button('reset');
                },
                success: function (json) {
                    if (json['success']) {
                        $('#cookie').slideUp(400, function () {
                            $('#cookie').remove();
                        });
                    }
                }
            });
        });
    }
}