import Hiraku from 'hiraku';

export class OffCanvas {

    constructor() {

        this.windowWidth = $(window).width();

        this.resize();

        this._init();
    }

    _init() {

        const plugin = this;

        window.onresize = () => {
            plugin.resize();
        };
    }

    resize() {

        // if (!this.$el.button.length) {
        //     return;
        // }

        // console.log('OffCanvas Resize');

        this.windowWidth = $(window).width();

        this.catalogMenu();
        this.filtersMenu();

        this._bindEvents();

        // console.log(this.$el.button);
    }




    catalogMenu() {

        const plugin = this;

        const breakpoint = 1199;

        const selector = {
            container: '#menu-mobile',
            outer: '#menu-mobile__outer',
            button: '.js-offCanvasCatalogTrigger'
        };

        const $outer = $(selector.outer);

        if (plugin.windowWidth > breakpoint) {
            $outer.addClass('d-none');
            return;
        }

        $outer.removeClass('d-none');

        const api = {
            load: 'index.php?route=extension/menu/mobile/load',
        };



        // console.log($outer);
        $.ajax({
            url: api.load,
            type: 'post',
            data: $(selector.container).data('query'),
            dataType: 'json',
            beforeSend: function (data) {
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (json) {

            // console.log(json);

            if (json.html) {
                $outer.html(json.html)
            }
        });


        let width = '80%';
        if (plugin.windowWidth > 400) {
            width = '360px';
        }

        const panelCatalog = new Hiraku(selector.container, {
            breakpoint: breakpoint,
            btn: selector.button,
            width: width,
            direction: "left"
        });

        $(document).on('click', selector.button, function () {
            panelCatalog.open();
        });
    }

    filtersMenu() {

        const plugin = this;

        const breakpoint = 1199;

        const selector = {
            panel: '.js-offCanvasFilters',
            button: '.js-offCanvasFiltersTrigger'
        };


        if (plugin.windowWidth > breakpoint) {
            $(selector.button).addClass('d-none');

            $(selector.panel).removeClass('js-hiraku-offcanvas-sidebar-right').removeAttr('style').removeAttr('aria-labelledby').removeAttr('aria-labelledby');
            return;
        }

        $(selector.button).removeClass('d-none');

        const panelFilters = new Hiraku(selector.panel, {
            breakpoint: breakpoint,
            btn: selector.button,
            width: '300px',
            direction: "right"
        });

        // console.log(panelFilters);

        $(document).on('click', selector.button, function () {
            panelFilters.open();
        });

    }


    _bindEvents() {



    }
}