export class Tariff {

    constructor() {

        this.selector = {
            widget: '.tariff__section',
            modal: '.tariff-modal'
        };

        this.$widget = $(this.selector.widget);

        if (!this.$widget.length) {
            return;
        }

        this.$modal = $(this.selector.modal);

        this.bindEvents();

        this.resize();
    }

    bindEvents() {

        const plugin = this;

        window.addEventListener("resize", function () {
            plugin.resize();
        });

        plugin.$modal.on("show.bs.modal", function (event) {
            plugin.mutateModalOpen(event);
        });

        plugin.$modal.on("hidden.bs.modal", function () {
            plugin.mutateModalClose($(this));
        });
    }

    resize() {
        this.windowWidth = $(window).width();

        this.init();
    }

    init() {

        // console.log(this.$widget);

        // if (this.windowWidth >= 768) {
        //     $(document).on('click', '.tariff-section a.nav-link', function () {
        //
        //         let $newTab = $($(this).attr('href'));
        //
        //         var offset = 100;
        //         if ($(this).hasClass('nav-icon')) {
        //             offset = 250;
        //         }
        //
        //         $('html, body').animate({
        //             scrollTop: $newTab.offset().top - offset
        //         }, 300);
        //     });
        // }
    }

    mutateModalOpen(event) {

        const $button = $(event.relatedTarget);
        const $modal = $(event.currentTarget);
        const $modalForm = $modal.find('form');

        //
        // const $modalTitle = $modal.find('.tariff-modal-title');
        // const defaultTitle = $modalTitle.data('default');
        // let title = $button.data('title');
        // if (title) {
        //     $modalTitle.html(title);
        // } else if (defaultTitle) {
        //     $modalTitle.html(defaultTitle);
        // }

        $modalForm.find('[name^=context]').remove();
        let context = $button.attr('data-context');

        if (context && (typeof context === 'string')) {
            context = JSON.parse(context) || {}
        }
        if (typeof context === 'object') {
            for (let key in context) {
                if (context.hasOwnProperty(key)) {
                    let fieldName = "context[" + key + "]";
                    let html = '<input type="hidden" name="' + fieldName + '" value="' + context[key] + '"/>';
                    $modalForm.append(html);
                }
            }
        }

        // REPLACE DATA
        let replaceData = $button.attr('data-replace');
        if (replaceData && (typeof replaceData === 'string')) {
            replaceData = JSON.parse(replaceData) || {}
        }

        if (typeof replaceData !== 'object' || this.isEmptyObj(replaceData)) {
            return;
        }

        if (replaceData.hasOwnProperty('title')) {
            $modalForm.find('.tariff-modal-title').html(replaceData.title).removeClass('d-none');
        }
        if (replaceData.hasOwnProperty('name')) {
            $modalForm.find('.tariff-modal-name').html(replaceData.name).removeClass('d-none');
        }


        if (replaceData.special && replaceData.price) {

            $modalForm.find('.tariff-modal-price-current').html(replaceData.special).removeClass('d-none');

            let old = parseInt(replaceData.price.replace(/ /g, '').match(/\d+/));
            let special = parseInt(replaceData.special.replace(/ /g, '').match(/\d+/));

            let discount = old - special;

            if (discount) {
                console.log('sale');
                $modalForm.find('.tariff-modal-discount').removeClass('d-none').html('Скидка ' + discount + ' руб.');
            }

        } else if (replaceData.price) {
            $modalForm.find('.tariff-modal-price-current').html(replaceData.price).removeClass('d-none');
        }


        // console.log(context);
    }

    mutateModalClose($modal) {
        const $modalForm = $modal.find('form');

        const selectors = ['.tariff-modal-title', '.tariff-modal-name', '.tariff-modal-price-current', '.tariff-modal-discount', '.tariff-modal-price-old'];

        $.each(selectors,function(index,value){

            // действия, которые будут выполняться для каждого элемента массива
            // index - это текущий индекс элемента массива (число)
            // value - это значение текущего элемента массива

            $modalForm.find(value).addClass('d-none').html('');
        });

        $modalForm.find('[name^=context]').remove();

        $modalForm.removeClass('was-validated');
    }


    isEmptyObj(obj) {
        return Object.getOwnPropertyNames(obj).length === 0;
    }
}