import {SeoLink} from './seolink';

import InfiniteScroll from 'infinite-scroll';

export class AjaxPagination {

    constructor(element, options) {

        this.element = element;

        this.$container = $(element);

        this.selectors = {
            widget: '.js-InfinityWidget',
            page: '.js-InfinityPage',
            loader: '.js-InfinityLoader',
            button: '.js-InfinityButton',
            pagination: '.js-InfinityPagination',
            item : {
                link: '.js-InfinityPage a'
            }
        };


        this.$widget = this.$container.closest(this.selectors.widget);

        this.$button = this.$widget.find(this.selectors.button);
        this.$pagination = this.$widget.find(this.selectors.pagination);
        this.$loader = this.$widget.find(this.selectors.loader);


        if (!this.$button.length) {
            return;
        }

        // this.api = this.$button.attr('data-api');

        var urlObj = new URL(this.$button.data('api'));
        // urlObj.searchParams.set('page', '{{#}}');
        urlObj.searchParams.delete('page');
        var hrefOverwrite = urlObj.toString();
        this.api = hrefOverwrite;


        this.init();

        //
        // console.log(hrefOverwrite);

        // this.infScroll = new InfiniteScroll( element, {
        //     path: '.js-InfinityPath',
        //     // path: hrefOverwrite,
        //     // append: false,
        //     // scrollThreshold: false,
        //     // button: '.js-InfinityButton',
        //     responseType: 'json',
        //     debug: true
        // });




    }

    init() {

        if (!this.$button.length) {
            return;
        }

        const plugin = this;

        plugin.infScroll = new InfiniteScroll( plugin.element, {
            // path: '.js-InfinityPath',
            path: function() {
                return plugin.api + '&page=' + (this.pageIndex + 1);
            },
            history: false,
            append: false,
            checkLastPage: false,
            loadOnScroll : false,
            scrollThreshold: false,
            // button: '.js-InfinityButton',
            responseType: 'json',
            // debug: true
        });


        if ( 'scrollRestoration' in history ) {
            history.scrollRestoration = 'manual';
        }

        plugin.$container.on('click', plugin.selectors.item.link, function (e) {

            plugin._history(null, null, false);

            var $page = $(this).closest(plugin.selectors.page);

            plugin._history(null, {
                page: $page.data('page') || 1
            }, false);

            // e.stopPropagation();
            // e.preventDefault();
        });


        plugin.$button.on('click', (e) => {

            plugin.infScroll.loadNextPage();

            // console.log(plugin.infScroll);
        });

        this.proxyElem = document.createElement('div');

        plugin.infScroll.on( 'request', function( response ) {
            plugin.$loader.removeClass('d-none');
            plugin.$button.prop('disabled', true).addClass('d-none');
        });

        plugin.infScroll.on( 'history', function( title, path ) {

        });

        plugin.infScroll.on( 'load', function( response ) {

            // console.log(response);

            plugin.$loader.addClass('d-none');

            if (!response.success) {
                return;
            }

            // const coords = plugin._getCoords(plugin.$button[0]);

            // const coords = plugin._getDocumentScroll();


            // parse JSON
            const data = response.success;
            // do something with JSON...

            // console.log(data);

            plugin.proxyElem.innerHTML = data.html;

            const items = plugin.proxyElem.querySelectorAll(plugin.selectors.page);

            plugin.infScroll.appendItems( items );




            // if ($(window).width() < 1199) {
            //
            //     const $lastPage = plugin.$widget.find('.products-page').last();
            //
            //     if ($lastPage.length) {
            //         const coords = plugin._getCoords($lastPage[0]);
            //         $('html, body').animate({scrollTop: coords.top}, 500, 'linear');
            //     }
            // }


            const $lastPage = plugin.$widget.find(plugin.selectors.page).last();

            if ($lastPage.length) {
                const coords = plugin._getCoords($lastPage[0]);
                $('html, body').animate({scrollTop: coords.top - 60}, 500, 'linear');
            }


            // ------------------

            const seoLinks = new SeoLink('[data-link]');
            seoLinks.init();

            if (data.hasOwnProperty('pagination')) {

                plugin.$pagination.html(data.pagination.value);

                if (data.pagination.hasOwnProperty('page') && data.pagination.page < data.pagination.pages) {
                    plugin.$button.find('span').html(data.pagination.button_more.text);
                    plugin.$button.prop('disabled', false).removeClass('d-none');
                }

            }
        });


    }

    _getDocumentScroll() {
        const scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );

        return {
            top: pageYOffset,
            bottom: pageYOffset + document.documentElement.clientHeight,
            height: scrollHeight
        };
    }

    _getCoords(elem) {
        const box = elem.getBoundingClientRect();

        return {
            top: box.top + pageYOffset,
            left: box.left + pageXOffset
        };

    }

    _disableScrolling(){
        const x=window.scrollX;
        const y=window.scrollY;
        window.onscroll=function(){window.scrollTo(x, y);};
    }

    _enableScrolling(){
        window.onscroll=function(){};
    }

    // Не работает. Реализовать: возврат на текущее состояние страницы (+прокрутка) при переходе в карточку и обратно
    // https://github.com/metafizzy/infinite-scroll/blob/master/js/history.js
    _history(href, params, push = false) {

        console.log('history');

        const plugin = this;

        if (!(window.history && history.pushState)) {
            return;
        }

        href = href || window.location.href;
        params = params || {};


        var url = new URL(href);
        for (var param in params) {
            if (params.hasOwnProperty(param)) {
                url.searchParams.set(param, params[param]);
            }
        }
        var hrefOverwrite = url.toString();

        console.log(hrefOverwrite);

        if (push) {
            history.pushState(null, null, hrefOverwrite.replace('%2F', '/'));
        } else {
            history.replaceState(null, null, hrefOverwrite.replace('%2F', '/'));
        }


        //console.log(hrefOverwrite)

        // ga( 'set', 'page', location.pathname );
        // ga( 'send', 'pageview' );
    }

}