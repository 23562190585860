
export default class Search {

    constructor() {

        this.$search = $('.search');

        this.api_url = $('base').attr('href') + 'index.php?route=product/search';
    }

    init() {

        if (!this.$search.length) {
            return;
        }

        const plugin = this;

        plugin.$search.on('click', 'button.btn-search', function () {
        	const $input = $(this).closest('.search').find('input');
            const text = $input.val();

        	let url = plugin.api_url;

        	if (text) {
        		url += '&search=' + encodeURIComponent(text);
        	}

        	window.location = url
        });

        plugin.$search.on('keydown', 'input', function (e) {
        	if (e.keyCode === 13) {
        		$(this).closest('.search').find("button.btn-search").trigger('click');
        	}
        });

    }
}